import React from 'react';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';
import { InstantSearch, Configure } from 'react-instantsearch-dom';

import { QueryFragments } from '@querys/queryFragments'; // eslint-disable-line

// Components
import Layout from '@components/layout';
import { useBlogQuery } from '@querys/useBlogQuery';
import { PageMeta } from '@components/pageMeta';
import { PortalGrid } from '@components/portals/portalGrid';
import { searchClient } from '@lib/algolia';
import { BlogFilters } from '../components/nav/filters/blogFilters';
import { useSiteContext } from '@lib/context';

const FeaturedLinks = loadable(() =>
  import('../components/pageModules/modules/featuredLinks'),
);

const BlogPortalTemplate = ({ data: { page } }) => {
  const { state } = useSiteContext();

  const { seo, config } = page || {};
  const { nodes } = useBlogQuery();

  //if state.blogFilters.label filter nodes by label
  const filteredNodes = nodes.filter(({ category }) => {
    if (state.blogFilters.label) {
      return category?.title === state.blogFilters.label;
    }
    return true;
  });

  return (
    <Layout config={config}>
      {/* SEO */}
      <PageMeta {...seo} />
      {/* Event Grid */}
      <InstantSearch searchClient={searchClient} indexName='blogs'>
        <Configure hitsPerPage={600} />
        <BlogFilters />
        <PortalGrid nodes={filteredNodes} />
      </InstantSearch>
      {/* Module at bottom of page */}
      <FeaturedLinks className='pt-space-m pb-space-m' reversed />
    </Layout>
  );
};

export default BlogPortalTemplate;

export const simpleBlogPortalQuery = graphql`
  query standardBlogPortalQuery($slug: String!) {
    page: sanityBlogPortal(slug: { current: { eq: $slug } }) {
      seo {
        ...SEO
      }
      title
      config: pageConfig {
        reverseHeader
        unpinnedHeader
      }
    }
  }
`;
