import { graphql, useStaticQuery } from 'gatsby';

import { QueryFragments } from './queryFragments'; // eslint-disable-line

export const useBlogQuery = () => {
  const { allSanityBlog } = useStaticQuery(graphql`
    query {
      allSanityBlog(sort: { fields: [date], order: DESC }) {
        nodes {
          title
          category {
            title
          }
          slug {
            current
          }
          featuredImage {
            ...ImageWithPreview
          }
          date(locale: "NZ", formatString: "DD MMMM yyyy")
          excerpt
        }
      }
    }
  `);
  return allSanityBlog || {};
};
