import React from 'react';
import {
  motion,
  AnimatePresence,
  useViewportScroll,
  useTransform,
} from 'framer-motion';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import cx from 'classnames';

import { useFilters } from '@lib/context';
import { useSiteContext } from '@lib/context';
import { FilterSearchBox } from '@components/search/filterSearchBox';
import { MobileFilters } from './mobileFilters';
import { DesktopSearch } from './desktopSearch';
import { useBlogCategoryQuery } from '@querys/useBlogCategory';

export const BlogFilters = () => {
  const breakpoints = useBreakpoint();

  const { state } = useSiteContext();
  const { nodes } = useBlogCategoryQuery();

  const [searchActive, setSearchActive] = React.useState(false);
  const toggleSearch = () => setSearchActive((prev) => !prev);

  const { updateBlogLabel } = useFilters();

  const { scrollY } = useViewportScroll();
  const height = useTransform(scrollY, [0, 163], [192, 32]);

  return (
    <>
      {breakpoints.lg && (
        <div style={{ paddingTop: '294px' }} className='w-full' />
      )}
      <motion.div
        style={{ paddingTop: breakpoints.lg && height }}
        className={cx(
          'px-gutter pb-8 flex gap-y-10 flex-col lg:flex-row items-center w-full justify-between nav-blur',
          {
            'z-50': searchActive && !breakpoints.lg,
            'z-20': !searchActive,
            'fixed top-0 left-0 z-20': breakpoints.lg,
            'pt-40 relative': !breakpoints.lg,
          },
        )}>
        <span className='blockH2 text-teal'>Media</span>
        {breakpoints.lg && (
          <div className='hidden lg:flex gap-x-3 items-center'>
            <AnimatePresence exitBeforeEnter>
              {searchActive ? (
                <motion.div
                  key={searchActive}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}>
                  <FilterSearchBox />
                </motion.div>
              ) : (
                <motion.div
                  className='flex gap-x-3 items-center'
                  key={searchActive}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}>
                  {nodes.map(({ title }, i) => (
                    <button
                      key={i}
                      onClick={() => {
                        state.blogFilters.label === title
                          ? updateBlogLabel(false)
                          : updateBlogLabel(title);
                      }}
                      className={cx(
                        'btn-standard bg-teal bg-opacity-10 text-teal transition-standard',
                        {
                          '!bg-opacity-100 !text-white':
                            state.blogFilters.label === title &&
                            state.blogFilters.label,
                        },
                      )}>
                      {title}
                    </button>
                  ))}
                </motion.div>
              )}
            </AnimatePresence>
            <DesktopSearch
              searchActive={searchActive}
              toggleSearch={toggleSearch}
            />
          </div>
        )}
      </motion.div>

      {!breakpoints.lg && (
        <MobileFilters
          searchOpen={searchActive}
          setSearchOpen={setSearchActive}
          media={nodes}
        />
      )}
    </>
  );
};
