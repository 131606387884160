import { graphql, useStaticQuery } from 'gatsby';

import { QueryFragments } from './queryFragments'; // eslint-disable-line

export const useBlogCategoryQuery = () => {
  const { allSanityBlogCategory } = useStaticQuery(graphql`
    query {
      allSanityBlogCategory {
        nodes {
          title
        }
      }
    }
  `);
  return allSanityBlogCategory || {};
};
